import { useEffect, useMemo, useRef } from "react";
import useDrag from "../../../hooks/useDrag";
// import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { ReactComponent as Arrow } from "../../../assets/icons/timeline/audio-length-arrow-white.svg";
import "./SequenceLengthIndicator.css";
import { useDomRef } from "../../../hooks/useDomRef";
import { useTimeline, scaledSpaceToUnitSpace } from "../../../contexts/TimelineProvider/TimelineProvider";
import { useGlobalAudioState } from "../../../contexts/GlobalAudioProvider/GlobalAudioProvider";
import { OBJECT_ROW_HEIGHT, TOP_STRIP_HEIGHT, WAVE_HEIGHT } from "../TimelinePanel";
import { useInteractivityBuilderState } from "../../../contexts/InteractivityBuilderProvider";
import { LessonPagesActions, useLessonPagesDispatch } from "../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { getLastAudioTimeStamp, getTimeLineSequenceLengthInPx } from "../helpers";
import { useAudioManagerStore } from "../../../contexts/PageAudioManager";

const RENDER_OUT_OF_VIEW_X = -10000;

export default function SequenceLengthIndicator() {
  const [ref, setRef] = useDomRef();
  const { audioOnPageExists } = useGlobalAudioState();
  const [timeline, setTimeline] = useTimeline();
  const [isDragging] = useDragBulge(ref);
  const { sequencedObjectsAmount } = timeline;
  const translateX = getTimeLineSequenceLengthInPx(timeline);
  const narrationAudios = useAudioManagerStore((state) => state.narrationAudios);
  const audioEffects = useAudioManagerStore((state) => state.audioEffects);

  const draggerHeight = OBJECT_ROW_HEIGHT * sequencedObjectsAmount;
  const top = `${TOP_STRIP_HEIGHT + (audioOnPageExists ? WAVE_HEIGHT : 0)}px`;

  useEffect(() => {
    const lastAudioEndTime = Math.max(getLastAudioTimeStamp(narrationAudios), getLastAudioTimeStamp(audioEffects));

    if (timeline.sequenceLength < lastAudioEndTime) {
      setTimeline({
        type: "SET_SEQUENCE_LENGTH",
        payload: lastAudioEndTime,
      });
    }
  }, [narrationAudios, audioEffects]);

  return (
    <>
      <div
        className={isDragging ? "dragging strip" : "strip"}
        ref={setRef}
        style={{
          top,
          height: draggerHeight,
          minHeight: OBJECT_ROW_HEIGHT,
          width: 20,
          transform: `translateX(${translateX === 0 ? RENDER_OUT_OF_VIEW_X : translateX}px)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Arrow />
      </div>
      {/* <div
        className="cover"
        style={{
          minHeight: OBJECT_ROW_HEIGHT,
          height: draggerHeight,
          top,
          width: startX,
        }}
      ></div> */}
    </>
  );
}
function useDragBulge(node: HTMLDivElement | null): [boolean] {
  const [timeline, setTimeline] = useTimeline();
  const { interactivityBuilderInstance } = useInteractivityBuilderState();
  const lessonDispatch = useLessonPagesDispatch();
  const { audioLength } = useGlobalAudioState();
  const narrationAudios = useAudioManagerStore((state) => state.narrationAudios);
  const audioEffects = useAudioManagerStore((state) => state.audioEffects);
  const lastAudioEndTime = Math.max(getLastAudioTimeStamp(narrationAudios), getLastAudioTimeStamp(audioEffects));
  const { clippedSpace, widthInPx, sequenceLength } = timeline;
  const gestureHandlers = useMemo<Parameters<typeof useDrag>[1]>(() => {
    return {
      debugName: "LengthIndicator/useDragBulge",
      lockCSSCursorTo: "ew-resize",
      onDragStart() {
        const initialLength = sequenceLength;
        let finalUnitPosition: number;
        return {
          onDrag(dx) {
            const deltaInUnits = scaledSpaceToUnitSpace(dx, clippedSpace, widthInPx);
            finalUnitPosition = initialLength + deltaInUnits;
            if (finalUnitPosition < audioLength) {
              finalUnitPosition = audioLength;
            } else if (finalUnitPosition < lastAudioEndTime) {
              finalUnitPosition = lastAudioEndTime;
            }

            if (finalUnitPosition <= 0) {
              finalUnitPosition = lastAudioEndTime || 1;
            }

            setTimeline({
              type: "SET_SEQUENCE_LENGTH",
              payload: finalUnitPosition,
            });
          },
          onDragEnd(dragHappened) {
            if (!dragHappened || typeof finalUnitPosition === "undefined") {
              return;
            }
            const snappedFinal = Math.round(finalUnitPosition);
            setTimeline({
              type: "SET_SEQUENCE_LENGTH",
              payload: snappedFinal,
            });
            lessonDispatch({
              type: LessonPagesActions.UPDATE_SEQUENCE_LENGTH,
              payload: snappedFinal,
            });
          },
        };
      },
    };
  }, [sequenceLength, widthInPx, clippedSpace.range.start, clippedSpace.range.end, audioLength]);

  return useDrag(node, gestureHandlers);
  // useLockFrameStampPosition(isDragging, -1)
}
