export const handleMetaVariableClick = ({
  key,
  selectedPanel,
  addNewMetaVariable,
  setMetaVariable,
  metaVariables,
}: {
  key: string;
  selectedPanel: string;
  addNewMetaVariable: (variable: {
    type: string;
    key: string;
    value: boolean;
    name: string;
    choices: string[];
  }) => void;
  setMetaVariable: (key: string, value: boolean) => void;
  metaVariables: Record<string, string | number | boolean>;
}) => {
  if (selectedPanel !== "advanced") return;

  const openKey = `${key}_open`;

  if (!(openKey in metaVariables)) {
    addNewMetaVariable({ type: "BOOLEAN", key: openKey, value: true, name: "Open State", choices: [] });
  } else if (!metaVariables[openKey]) {
    setMetaVariable(openKey, true);
  }
};
