import { EffectAudioState, NarrationAudioState } from "../../contexts/PageAudioManager/types";
import { clippedSpaceFromUnitSpace, TimelineState } from "../../contexts/TimelineProvider/TimelineProvider";
import { AnimatedObjectManifest } from "../../lib/interactivity/TimelineConverter/TimelineConverter";
import { AnimatedMetaVariable } from "../../lib/SmartObject/store";
import type { IInteractiveAudio } from "../../models/IInteractiveAudio";
import pick from "lodash/pick";

export const audioManifestPropertiesWhitelist = <TAudioType extends IInteractiveAudio>(
  audio: TAudioType,
): TAudioType => {
  if ("ttsProps" in audio) {
    audio.ttsProps = pick(audio.ttsProps, ["language", "voice", "prosodyRate", "narrationText", "pronunciationText"]);
  }

  return pick(audio, [
    "objectId",
    "type",
    "start",
    "end",
    "duration",
    "input",
    "savedText",
    "title",
    "description",
    "language",
    "parentObjectId",
    "ttsProps",
  ]) as TAudioType;
};

const ensureRelativePath = (path: string) => {
  if (path[0] === "/") {
    return path.substring(1);
  } else {
    return path;
  }
};

const ASSET_API_ENDPOINT = "/api/Asset";
/**
 * WARNING:
 *  When saving AUDIO url in the manifest we need to keep it "relative"
 *  that's needed for LessonPlayer and other services
 *  They expect it to be appended to their current path
 *  Do NOT save it as absolute it is going to crash lesson player
 */
export const audioBlobToApi = (url: string) =>
  url.includes(ASSET_API_ENDPOINT.substring(1))
    ? url
    : `/${ASSET_API_ENDPOINT}/${url}`.replace(new RegExp("//", "g"), "/");
export const audioApiToBlob = (url: string) =>
  ensureRelativePath(url.replace(new RegExp(ASSET_API_ENDPOINT, "gmi"), ""));

export const getLastAudioTimeStamp = (audios: NarrationAudioState[] | EffectAudioState[]) => {
  let time = 0;

  audios.forEach(({ end }) => {
    if (end > time) {
      time = end;
    }
  });

  return time;
};

export const getLastAnimatedObjectTimeStamp = (objects: AnimatedObjectManifest[]) => {
  let time = 0;

  objects.forEach(({ frames }) => {
    const lastFrame = frames ? frames[frames.length - 1] : null;

    if (lastFrame && lastFrame.timestamp > time) {
      time = lastFrame.timestamp;
    }
  });

  return time;
};

export const getLastObjectVisibilityTimeStamp = (objects: AnimatedObjectManifest[]) => {
  let time = 0;

  objects.forEach(({ end }) => {
    if (end && end > time) {
      time = end;
    }
  });

  return time;
};

export const getLastAnimatedMetaVariableTimeStamp = (variables: AnimatedMetaVariable[]) => {
  let time = 0;

  variables.forEach(({ oneshot, interpolated }) => {
    const lastOneshotFrame = oneshot ? oneshot[oneshot.length - 1] : null;
    const lastInterpolatedFrame = interpolated ? interpolated[interpolated.length - 1] : null;

    if (lastOneshotFrame && lastOneshotFrame.timestamp > time) {
      time = lastOneshotFrame.timestamp;
    }

    if (lastInterpolatedFrame && lastInterpolatedFrame.timestamp > time) {
      time = lastInterpolatedFrame.timestamp;
    }
  });

  return time;
};

export const getTimeLineSequenceLengthInPx = (timeline: TimelineState) => {
  const {
    clippedSpace,
    widthInPx,
    scaledSpace: { leftPadding },
    sequenceLength,
  } = timeline;

  const startInUnitSpace = sequenceLength;
  let startX = clippedSpaceFromUnitSpace(startInUnitSpace, leftPadding, clippedSpace, widthInPx);
  const endX = widthInPx;
  let translateX: number;
  if (startX > endX) {
    translateX = 0;
  } else {
    if (startX < 0) {
      startX = 0;
    }
    translateX = startX;
  }

  return translateX;
};
