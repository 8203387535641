import { CSSProperties, useEffect, useState } from "react";
import styles from "../AdvancedPanel.module.css";
import { ReactComponent as Arrow } from "../../../../../../assets/icons/arrow-down-white.svg";
interface CollapseProps {
  title: string;
  children: React.ReactNode;
  isOpen?: boolean;
  onToggle?: (newState: boolean) => void;
  id?: string;
}

export function Collapse({ title, children, isOpen, onToggle, id }: CollapseProps) {
  const [open, setOpen] = useState(isOpen || false);

  useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen);
    }
  }, [isOpen]);
  const handleToggle = () => {
    const newState = !open;
    setOpen(newState);
    onToggle?.(newState);
  };

  const arrowStyles = {
    transform: open ? "rotate(180deg)" : "",
    transition: "all 150ms ease-out",
  } as CSSProperties;

  const childrenStyles = {
    display: open ? "block" : "none",
  };

  return (
    <div className={styles.collapse} id={`collapse-${id}`}>
      <div className={styles.collapseHeader}>
        <div id={`title-tab-${id}`} className={styles.title}>
          {title}
        </div>
        <button className={styles.collapseButton} onClick={handleToggle}>
          <Arrow style={arrowStyles} />
        </button>
      </div>
      <div style={childrenStyles}>{children}</div>
    </div>
  );
}
