import { useEffect, useRef } from "react";
import { useMetaVariableStore } from "../lib/SmartObject/store";

const useCollapseState = (dataKey: string) => {
  const setMetaVariable = useMetaVariableStore((s) => s.setMetaVariable);
  const metaVariables = useMetaVariableStore((s) => s.metaVariables);
  const openKey = `${dataKey}_open`;
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!(openKey in metaVariables)) {
      setMetaVariable(openKey, false);
    }
  }, [openKey, metaVariables, setMetaVariable]);

  const handleCollapseToggle = (newState: boolean) => {
    setMetaVariable(openKey, newState);
  };

  const isOpen = Boolean(metaVariables[openKey]);

  return { isOpen, handleCollapseToggle, elementRef };
};

export default useCollapseState;
